export enum EEX_PATHS {
  DISCORD = "https://discord.com/invite/G6edujdbSU",
  LEGAL = "https://www.nmkr.io/legal",
  LEARN_MORE_WALLETS = "https://docs.cardano.org/new-to-cardano/types-of-wallets",
  ABOUT = "https://www.nmkr.io/about",
  DISCLAIMER = "https://www.nmkr.io/legal#terms",
  PRIVACY = "https://www.nmkr.io/legal",
  TERMS = "https://www.nmkr.io/legal#terms",
  TWITTER = "https://twitter.com/nmkr_io",
  TELEGRAM = "https://t.me/nmkrtoken",
  INSTAGRAM = "https://www.instagram.com/nmkr_io/",
  FAQ = "https://www.youtube.com/c/NMKR_IO",
}
