import { AppProvider, i18Init, namespaces, constants } from "@sundae/gov-toolkit";
import ReactDOM from "react-dom/client";
import { useI18N } from "@sundae/react-hooks";
import { Tooltip, QuestionMarkCircledIcon, Link, Image } from "@sundae/ui-toolkit";
import resourcesToBackend from "i18next-resources-to-backend";

import "./tailwind.scss";
import { EEX_PATHS } from "./types";

(async () => {
  i18Init(
    (language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`).then(({ default: resources }) =>
        callback(null, resources)
      );
    },
    (options) => {
      options.ns = [...namespaces, "nmkr"];
      options.debug = false;
      return options;
    }
  );
})();

const App = () => {
  const { t, i18n } = useI18N("nmkr");
  i18n.use(
    resourcesToBackend((language, namespace, callback) => {
      const path = `./translations/${language}/${namespace}.json`;
      import(path).then(({ default: resources }) => callback(null, resources));
    })
  );
  return (
    <AppProvider
      brand={{
        title: t("brand.title"),
        description: t("brand.description"),
        classes: {
          buttons: {
            all: "!rounded-full !text-black !font-bold",
            primary: "mx-auto !h-[40px] !pt-3 !pb-[11px] md:mx-0 py-3 px-6",
            secondary: "py-3 px-6 !text-black",
            colored: "!bg-none !bg-primary hover:!bg-primary-300",
            tabs: "!text-black",
          },
          text: {
            title: "",
            description: "",
          },
        },
        images: {
          audit_pending: "/static/images/results_pending.svg",
          casting_a_vote: "/static/images/casting_a_vote.svg",
          fatal_error: "/static/images/fatal_error.svg",
          not_enough_tokens: "/static/images/not_allowed.svg",
          proposal_created: "/static/images/proposal_created.svg",
        },
      }}
      componentProps={{
        header: {
          renderLogo: () => (
            <Link to={constants.paths.EPATHS.PROPOSAL_OVERVIEW}>
              <Image className="h-[20px]" src="/static/images/nmkr-governance-light.svg" />
            </Link>
          ),
          showThemeToggle: false,
          renderTertiaryNav: ({ HeaderButton }) => (
            <Tooltip
              trigger={
                <HeaderButton
                  className="!h-[40px] !w-[40px] rounded-3xl lg:ml-4"
                  onClick={() => window.open(EEX_PATHS.FAQ, "_blank")}
                >
                  <QuestionMarkCircledIcon className="!h-5 !w-5" />
                </HeaderButton>
              }
              tooltipLabel="Support"
            />
          ),
          navigationItems: [
            {
              to: "/",
              title: t("navigation.home"),
            },
            {
              to: "/create",
              title: t("navigation.create"),
            },
            {
              to: EEX_PATHS.ABOUT,
              title: t("navigation.about"),
              external: true,
              target: "_blank",
            },
            {
              dropdown: {
                menuTitle: t("navigation.more"),
                menuContent: [
                  {
                    to: EEX_PATHS.TWITTER,
                    title: t("navigation.twitter"),
                    description: t("navigation.twitter", { context: "description" }),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.TELEGRAM,
                    title: t("navigation.telegram"),
                    description: t("navigation.telegram", { context: "description" }),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.DISCORD,
                    title: t("navigation.discord"),
                    description: t("navigation.discord", { context: "description" }),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.INSTAGRAM,
                    title: t("navigation.instagram"),
                    description: t("navigation.instagram", { context: "description" }),
                    external: true,
                  },
                ],
              },
            },
          ],
        },
      }}
    />
  );
};

const target = document.querySelector("#app");
const root = target && ReactDOM.createRoot(target);
if (root) {
  root.render(<App />);
}
